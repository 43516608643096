import React from 'react';

import { Container, Row, Col } from '../components/react-grid';
import Layout from "../components/layout";
import SEO from "../components/seo";
import Pane from '../components/Pane';
import Hero from '../components/Hero';
import Button from '../components/Button';
import OverlayBlock from '../components/OverlayBlock';
import Slideshow from '../components/Slideshow';
import SlideshowImage from '../components/SlideshowImage';
import Pagination from '../components/Pagination';

import css from './about-us.module.scss';
import wayeLogo from '../images/waye-logo-white-medium.png';
import ql from '../images/quote-mark-left.svg';
import qr from '../images/quote-mark-right.svg';
import slideshowImage01 from '../images/sinead-slideshow-01.jpg';
import slideshowImage02 from '../images/sinead-slideshow-02.jpg';
import slideshowImage03 from '../images/sinead-slideshow-03.jpg';
import heroImage from '../images/about-us-hero-image.jpg';
import ourAudienceImage from '../images/about-us-our-audience-photo.jpg';
import ourSpecialistsImage from '../images/about-us-our-specialists-photo.jpg';
import founderImage from '../images/about-us-founder-photo.jpg';
import modelImage from '../images/about-us-model-photo.jpg';
import aboutussineadvideoscreenshot from '../images/about-us-sinead-video-screenshot.jpg';

import { FeaturedPartners, Press, FeaturedVideo } from './index.js';

import logoBloomberg from '../images/logo-bloomberg.svg';
import logoCornell from '../images/logo-cornell.svg';
import logoNyuStern from '../images/logo-nyu-stern.png';
import logoIbm from '../images/logo-ibm.svg';
import logoToronto from '../images/logo-university-toronto.svg';
import logoUn from '../images/logo-un.svg';
import logoAion from '../images/logo-aion.png';
import logoBybabba from '../images/logo-bybabba.png';
import logoHinge from '../images/logo-hinge.png';
import logoKairos from '../images/logo-kairos.png';
import logoLittlespoon from '../images/logo-littlespoon.png';
import logoProjectTsehigh from '../images/logo-project-tsehigh.png';
import logoCelsious from '../images/logo-celsious.png';
import logoAfrotech from '../images/logo-afrotech.png';
import logoCoveteur from '../images/logo-coveteur.png';
import logoWired from '../images/logo-wired.png';
import logoFashonista from '../images/logo-fashonista.png';
import logoLazaridisss from '../images/logo-lazaridis-ss.png';
import logoTedRogersss from '../images/logo-ted-rogers-ss.png';
import logoTheCut from '../images/logo-the-cut.png';
import logoGoop from '../images/logo-goop.png';

const PARTNER_LOGOS = [
  logoBloomberg,
  logoCornell,
  logoNyuStern,
  logoIbm,
  logoToronto,
  logoUn,
  logoAion,
  logoBybabba,
  logoHinge,
  logoKairos,
  logoLittlespoon,
  logoProjectTsehigh,
  logoCelsious,
  logoAfrotech,
  logoCoveteur,
  logoWired,
  logoFashonista,
  logoLazaridisss,
  logoTedRogersss,
  logoTheCut,
  logoGoop
];

const AboutUs = () => (
  <Layout name="about-us">
    <SEO title="About Us" keywords={[`WAYE`, `WAYE Talks`]} />

    <Pane name="landing" className={css.paneLanding}>
      <Hero 
        name="about-us"
        h1="Why"
        logo={wayeLogo}
        logo_alt='WAYE Talks'
        tagline="By the year 2030, more than 800 million jobs will be replaced by technology."
        tagline_source="- McKinsey Global Institute"
        img_src={heroImage}
      />
    </Pane>

    <Pane name="drill-down" className={css.paneDrilldown}>
      <OverlayBlock
        block-on-mobile
        className={css.paneDrilldown__overlayBlock}
        name="explore-waye-talks"
        action={true}
        action_url="/waye-talks"
        action_text="Explore WAYE Talks">

        We are bridging the gap between young entrepreneurs and the digital
        future by revolutionizing the way that young people learn about
        technology.
      </OverlayBlock>

      <Container className={css.drilldownGroup}>
        <Row>
          <Col xs="9" xs-offset="2" sm="5" sm-offset="7" className={css.drilldownGroup__col}>
            <ul className={css.drilldown}>
              <li>
                <div className={css.bullet}></div>
                WAYE educates young people on the intersection of business,
                technology, and the future, with a focus on non-traditional and
                minority markets.
              </li>
              <li>
                <div className={css.bullet}></div>
                WAYE supports young people in building career paths that will
                sustain the digital shift.
              </li>
              <li>
                <div className={css.bullet}></div>
                WAYE equips young people with a framework to evaluate emerging
                and disruptive technologies.
              </li>
              <li>
                <div className={css.bullet}></div>
                WAYE inspires and challenges how young people think about the
                future in order to expand their beliefs on all that could be
                possible, while fostering innovation to create.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="about-us-our-audience" className={css.ourAudienceGroup}>
      <div className={css.photo} style={{backgroundImage: `url(${ourAudienceImage})`}}></div>
      <div className={css.bodyTextGroup}>
        <Container>
          <Row className={css.bodyText}>
            <Col xs="12" sm="4" className={css.bodyText__col}>
              <h1>Our Audience</h1>

              <Row relative={true}>
                <Col xs="12" sm="2"></Col>
                <Col xs="10" xs-offset="1" sm="2" sm-offset="0" className={css.bodyText__colSub}>
                  <Button to="/community" className={css.button}>Explore Our Community</Button>
                </Col>
              </Row>
            </Col>
            <Col xs="10" xs-offset="1" sm="6" sm-offset="1" className={css.bodyText__text}>
              <p>
                Everyday 600+ Millennials and Gen Z tune into WAYE’s platforms
                to learn about the intersection of business, technology and the
                future.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </Pane>

    <Pane name="about-us-founder-model" heighted className={css.founderModelGroup}>
      <div className={css.founder}>
        <OverlayBlock 
          block-on-mobile
          className={css.founder__overlayBlock}
          align-right>
          Our Founder, Sinead Bovell.
          <small>MBA, B.B.A Finance</small>
        </OverlayBlock>
        <div className={css.photo} style={{backgroundImage: `url(${founderImage})`}}></div>
      </div>
      <div className={css.model}>
        <OverlayBlock 
          block-on-mobile
          className={css.model__overlayBlock}>Futurist, Speaker, Youth Tech Leader</OverlayBlock>
        <div className={css.photo} style={{backgroundImage: `url(${modelImage})`}}></div>
      </div>
    </Pane>

    <Pane name="content-body" className={css.aboutSineadGroup}>
      <Container>
        <Row>
          <Col xs="10" xs-offset="1" sm="8" sm-offset="2" className={css.aboutSinead}>
            <p>
              Recognizing the pace at which advanced technologies would soon outpace the 
              workforce, Sinead Bovell made it her mission to bridge the information gap 
              between young entrepreneurs and the digital future. In 2018, Bovell 
              founded WAYE (Weekly Advice for Young Entrepreneurs); and shortly after 
              launched WAYE Talks, a series of monthly events where she educates young 
              entrepreneurs on the intersection of business, technology, and the future. 
              The series went viral, with young people coming from across the world to 
              get involved. From Blockchain to Artificial Intelligence to the future of 
              Social Media, young people have turned to WAYE Talks for the tools and 
              the answers to the future.
            </p>
            <p>
              To date, Sinead has educated over 10, 000 young entrepreneurs on the 
              future of technology, and has spoken at world renowned institutions 
              including the United Nations, the US Chamber of Commerce, Cornell 
              University, and Bloomberg, on the intersection of business, technology, 
              and the future. Most recently, Sinead was appointed to the United Nations 
              International Telecommunications Union Generation Connect Visionaries 
              Board to offer strategic guidance on youth participation and empowerment 
              in digital cooperation. Sinead has served as a tech contributor for 
              publications such as Vogue, The Star, and The Globe and Mail, and was 
              recently recognized by Wired Magazine for her work in bringing ‘new faces 
              to the table in tech.’
            </p>
            <p>
              Prior to founding WAYE, Sinead received a degree in finance, a minor in 
              chemistry, and an MBA  from the University of Toronto, and worked as a 
              management consultant for A.T. Kearney
            </p>
            <p>
              Sinead is driven by her mission to build a progressive, informed, and 
              thriving society. One in which technology is built, as she says, “on the 
              right side of history.”
            </p>
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="about-us-sinead-slideshow">
      <FeaturedVideo videoId="E119-bs13cY" title='"The best thing that we can do about the future is prepare for it."' thumbnail={aboutussineadvideoscreenshot} />
    </Pane>

    <Pane name="about-us-featured-expertise" className={css.featuredExpertiseGroup}>
      <OverlayBlock 
        block-on-mobile
        className={css.featuredExpertiseGroup__overlayGroup}
        align-right
        name="featured-expertise" 
        modifier="featured-expertise" 
        action={true}
        action_url="/contact-us?inquire=sinead"
        action_text="Inquire with Sinead">
        Featured Expertise
      </OverlayBlock>

      <Container className={css.drilldownGroup}>
        <Row>
          <Col xs="9" xs-offset="2" sm="5" sm-offset="1" className={css.drilldownGroup__col}>
            <ul className={css.drilldown}>
              <li>
                <div className={css.bullet}></div>
                The future of work
              </li>
              <li>
                <div className={css.bullet}></div>
                Diversity, inclusivity and the future of work
              </li>
              <li>
                <div className={css.bullet}></div>
                The ethics of emerging technologies
              </li>
              <li>
                <div className={css.bullet}></div>
                A future with artificial intelligence
              </li>
              <li>
                <div className={css.bullet}></div>
                A future with advanced technologies
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Pane>

    <Pane name="about-us-our-specialists" className={css.ourSpecialistsGroup}>
      <div className={css.bodyTextGroup}>
        <Container>
          <Row className={css.bodyText}>
            <Col xs="10" xs-offset="1" sm="6" sm-offset="1" className={`${css.bodyText__text} ${css.bodyText__textSpecialists}`}>
              <p>
                WAYE is bringing industry experts to the forefront of key
                markets, giving them access and an opportunity to educate and
                influence the next working generation on the future of business
                and technology.
              </p>
            </Col>
            <Col xs="12" sm="4" sm-offset="1" className={`${css.bodyText__col} ${css.bodyText__colSpecialists}`}>
              <h1>Our Specialists</h1>

              <Row relative>
                <Col xs="10" xs-offset="1" sm="2" sm-offset="0" className={css.bodyText__colSub}>
                  <Button to="/community" className={css.button}>Explore Our Community</Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={css.photo} style={{backgroundImage: `url(${ourSpecialistsImage})`}}></div>
    </Pane>

    <Pane name="about-us-featured-partners">
      <FeaturedPartners logos={PARTNER_LOGOS} title="Featured Companies" modifier="AboutUs" />
    </Pane>

    <Press />
  </Layout>
);

/**
 * expose
 */

export default AboutUs;